//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    imgPattern: {
      type: String,
      default: '/img/concrate-texture-light.png'
    },
    imgBorder: {
      type: String,
      default: '/img/index/us-border.png'
    },
    imgSecondary: {
      type: String,
      default: '/img/index/us-bbq.png'
    },
    imgPrimary: {
      type: String,
      default: '/img/index/us.png'
    },
    oriantation: {
      type: String,
      default: 'imgInLeft'
    }

  }
}
