//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: {
      type: String,
      default: 'Keressen minket az elérhetőségeink egyikén'
    },
    promoButton: {
      type: Object,
      default () {
        return {
          link: '/kapcsolat',
          text: 'Tovább'
        }
      }
    }
  }
}
