//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    iconsWithText: {
      type: Array,
      default: null
    },
    bgImageClass: {
      type: String,
      default: 'bg-img-gray-dark'
    },
    colSize: {
      type: String,
      default: 'w-1/3'
    }
  },
  data () {
    return {

    }
  }
}
