//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    id: {
      type: String,
      default: 'others'
    },
    title: {
      type: String,
      default: 'Nézd meg további termékeinket'
    },
    description: {
      type: String
    },
    others: {
      type: Array,
      required: true
    },
    size: {
      type: String,
      default: 'grid-rows-4 grid-cols-1 xl:grid-cols-2 xl:grid-rows-2'
    }
  }
}
