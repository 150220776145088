//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    showIf: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    hasAccept: {
      type: Boolean,
      default: true
    },
    hasButtons: {
      type: Boolean,
      default: true
    },
    acceptText: {
      type: String,
      default: 'Ok'
    },
    isTransparent: {
      type: Boolean,
      default: false
    },
    half: {
      type: Boolean,
      default: false
    }
  }
}
