//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import HeroSection from '~/components/hero'
import GallerySection from '~/components/gallery'
import ImgWithTextRow from '~/components/imgWithTextRow'
import OthersSection from '~/components/othersSection'
import Promo from '~/components/promo'

export default {
  components: { VueSlickCarousel, HeroSection, ImgWithTextRow, OthersSection, GallerySection, Promo },
  data () {
    return {
      settings: {
        arrows: true,
        dots: true,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        touchThreshold: 5,
        responsive: [
          {
            breakpoint: 1081,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false
            }
          }
        ]
      },
      settingsLogos: {
        autoplay: true,
        arrows: true,
        dots: true,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 2,
        touchThreshold: 5,
        responsive: [
          {
            breakpoint: 1081,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false
            }
          }
        ]
      },
      news: [
        {
          title: 'Betonlépcső Veresegyházán',
          date: '2021.10.23.',
          description: 'A neves belsőépítész tervei alapján elkészített betonlépcsőket csúszásgátló felülettel készítettük a balesetveszély elhárítása érdekében. Bár az iskola belső használatra szánta a betonlépcsőt, de ezek a beton elemek a kültéren is megállják a helyüket.',
          img: '/img/hirek-veresegyhaz.png',
          category: { name: 'Referencia', value: 'referencia' }
        },
        {
          title: 'DJ Elfi ',
          date: '2021.10.23.',
          description: 'Megtiszteltetésként ért a felkérés ,hogy ebben a nehéz helyzetben segíthettünk. A tehetséges dj új live mix felvételeit. Mindenkit arra kérek,hogy ha lehetősége van támogatni művészeinket, zenészeinket, ne habozzon megtenni.',
          img: '/img/hirek-elfi.png',
          category: { name: 'Referencia', value: 'referencia' }
        },
        {
          title: 'Beton mosdó? - Igen',
          date: '2021.10.23.',
          description: 'Otthonunk tervezésénél mindenkiben ég a vágy, hogy valami hangulatos egyedi dolog kerüljön beépítésre lakásában, házában. Fürdőszoba egy fontos része az életünknek, hiszen itt kezdjük és fejezzük be napjainkat, jó hangulatot kell sugároznia. Manapság gyönyörű burkolatokból válogathatunk,de a gyönyörű csempékhez színes szanitereket kell választanunk a teljes enteriör érdekében. A Concrate Style cég  minőségi munkával, színben, formában és méretben is az ön igényeinek megfelelően gyártja termékeit.',
          img: '/img/hirek-mosdo.png',
          category: { name: 'Referencia', value: 'referencia' }
        },
        {
          title: 'Konyhakiállítás 2020',
          date: '2021.2.23.',
          description: 'A Magyar Lakberendezők Országos szövetségének standján, Kiszely Beáta a 2019 év lakberendezője által tervezett kültéri-kerti installációjának egyik központi eleme volt a kültéri beton konyha. Kialakítását úgy terveztük, hogy egy nagyon hasznos mosogató részt alakítottunk ki benne, mely egyben került kivitelezésre a pulttal.',
          img: '/img/hirek-konyha.png',
          category: { name: 'Bemutató', value: 'bemutato' }
        },
        {
          title: 'Hódít a beton Bolognában',
          date: '2021.1.10.',
          description: 'Minden ősszel megrendezik  a már hagyománnyá vált Cersaie kiállítást Bolognában, mely talán a világ legnagyobb burkolatokat és szanitereket bemutató rendezvénye.vHatalmas sikernek örvendett az alábbiakban látható tusolótálca, melyet a Concrate Style nem csak személyre szabott méretben, de egyedi tusoló falakkal együtt gyárt.',
          img: '/img/hirek-tusolo.png',
          category: { name: 'Bemutató', value: 'bemutato' }
        }
      ],
      logos: [
        {
          title: 'Cook & room',
          img: '/img/logos/cook_room_logo.png'
        },
        {
          title: 'Busuló juhász',
          img: '/img/logos/busulo_juhasz.jpg'
        },
        {
          title: 'Da Mario',
          img: '/img/logos/da_mario.jpg'
        },
        {
          title: 'Dryvit',
          img: '/img/logos/dryvit.png'
        },
        {
          title: 'Emerald',
          img: '/img/logos/emerald.png'
        },
        {
          title: 'Four seasons',
          img: '/img/logos/four_seasons_logo.jpg'
        },
        {
          title: 'Marché',
          img: '/img/logos/marche.png'
        },
        {
          title: 'Meto',
          img: '/img/logos/meto.png'
        },
        {
          title: 'Mol',
          img: '/img/logos/mol.png'
        },
        {
          title: 'Retro',
          img: '/img/logos/retro.jpg'
        },
        {
          title: 'Robinson',
          img: '/img/logos/robinson.jpg'
        },
        {
          title: 'Rokfort',
          img: '/img/logos/rokfort.png'
        },
        {
          title: 'Singer design',
          img: '/img/logos/singerdesign.jpg'
        },
        {
          title: 'SMP',
          img: '/img/logos/smp.jpg'
        },
        {
          title: 'Záév',
          img: '/img/logos/zaev.png'
        }
      ],
      heroIcons: [
        {
          title: '434',
          text: 'interior design'
        },
        {
          title: '8',
          text: 'különböző terület'
        },
        {
          title: '15',
          text: 'év tapasztalat'
        }
      ],
      otherItems: [
        {
          title: '<span class="text-concrate">Kül</span>tér',
          img: '/img/index/services-outdoor.png',
          text: 'Kültéren is számtalan lehetőség nyílik a design beton termékek felhasználására. Kerti tipegő, medenceszegély csúszásgátló felülettel a kertek, udvarok biztonságos közlekedését ,használatát segítik. A virágládák, beton magaságyások a nővények tárolásán felül a kerti design megépítésében válik hasznunkra. Betonpárkány, kerítés fedkövek használatával a házunk nagyobb harmóniába kerül.'
        },
        {
          title: '<span class="text-concrate">Köz</span>tér',
          img: '/img/index/services-busstop.png',
          text: 'Ön exteriőr tervező vagy tájépítészként kereste fel oldalunkat? Van egy jó hírem, az elkövetkező munkái során nem kell a sablonizált termékek közül választania. Cégünk vállalja egyedi elképzelései megvalósítását. Legyen az logózott virágláda, egyedi formavilágú pad, Köztéri bútor, lépcső vagy ágyás szegély.'
        },
        {
          title: '<span class="text-concrate">Bel</span>tér',
          img: '/img/index/services-indoor.png',
          text: 'Belsőépítészek legnagyobb örömére vállalunk irodaházak, szállodák, családiházak belső beton elemeinek gyártását, úgymint padlóburkolat, falburkolat, kandalló, mosdó vagy akár konyhapult. Egyedi tervek alapján valósítjuk meg álmait  beltéri design beton elemekből.  '
        },
        {
          title: '<span class="text-concrate">Virág</span>láda',
          img: '/img/index/viraglada.png',
          text: 'Mi lehetne jobb választás nővényeinek, mint egy strapabíró beton virágláda? Látszóbeton felülettel gyártjuk így minden modern teraszra, kertbe, de akár étterme vagy irodája környezetét is feldobja. Egyedi kialakítása lehetővé teszi a kisebb szintkülönbségek kiküszöbölését. Mondja el igényeit és mi megvalósítjuk elképzeléseit. '
        }
      ],
      images: [
        {
          thumb: '/img/index/g1.jpg',
          url: '/img/gallery/concrate_style_otthon_magazin_1.png'
        },
        {
          thumb: '/img/index/g2.jpg',
          url: '/img/gallery/kulteri_BBQ_s02_v02.jpg'
        },
        {
          thumb: '/img/index/g3.jpg',
          url: '/img/gallery/kulteri_BBQ_s02_v02_4.jpg'
        },
        {
          thumb: '/img/index/g4.jpg',
          url: '/img/gallery/kulteri_BBQ_v03_img06.png'
        },
        {
          thumb: '/img/gallery/grill-1.jpg',
          url: '/img/gallery/kulteri_BBQ_v03_img04.png'
        },
        {
          thumb: '/img/gallery/grill-2.jpg',
          url: '/img/gallery/kulteri_BBQ_s02_v02_3.jpg'
        },
        {
          thumb: '/img/gallery/busz-1.jpg',
          url: '/img/gallery/fehervar_buszmegallo_03.png'
        },
        {
          thumb: '/img/gallery/busz-2.jpg',
          url: '/img/gallery/fehervar_buszmegallo_01 .png'
        },
        {
          thumb: '/img/gallery/medence-1.jpg',
          url: '/img/gallery/makra_zoltan_kulteri_konyha_v02.jpg'
        }
      ],
      galleryHeader: [
        {
          name: 'Összes fotó',
          value: 'all',
          url: '/galeria'
        },
        {
          name: 'Kültér',
          value: 'kulter',
          url: '/galeria/?tema=castle'
        },
        {
          name: 'Köztér',
          value: 'kozter',
          url: '/galeria/?tema=weddings'
        },
        {
          name: 'Beltér',
          value: 'belter',
          url: '/galeria/?tema=events'
        },
        {
          name: 'Virágláda',
          value: 'viraglada',
          url: '/galeria/?tema=events'
        }
      ]
    }
  },
  head () {
    return {
      title: 'Concrate style - minden ami beton ',
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: 'description',
          name: 'description',
          content: '15 év szakmai tapasztalattal gyártunk exkluzív egyedi beton termékeket: beton szaniterek, kültéri beton bútorok, beton virágládák, konyhai beton munkapultok, medenceszegély kövek és burkolatok.'
        }
      ]
    }
  },
  methods: {
    showNext () {
      this.$refs.carousel.next()
      this.$refs.carouselLogos.next()
    },
    showPrev () {
      this.$refs.carousel.prev()
      this.$refs.carouselLogos.prev()
    }
  }
}
