//
//
//
//
//
//
//
//
//
//

import Box from '~/components/box'
export default {
  components: { Box },
  props: {
    bgImageClass: {
      type: String,
      default: 'bg-hero'
    },
    heightClass: {
      type: String,
      default: 'h-screen'
    },
    icons: {
      type: Array,
      default: null
    },
    boxColSize: {
      type: String,
      default: 'w-1/3'
    }
  },
  data () {
    return {

    }
  }
}
