import { render, staticRenderFns } from "./gallery.vue?vue&type=template&id=3b47d6e4&"
import script from "./gallery.vue?vue&type=script&lang=js&"
export * from "./gallery.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Modal: require('/Users/kt/Downloads/concratestyle-zip/components/modal.vue').default})
